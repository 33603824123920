class FakeSelect
  constructor: (@fake_select) ->
    @add_listeners()

  add_listeners: ->
    @fake_select.addEventListener 'click', (e) =>
      e.preventDefault()
      @toggle_visibility(@fake_select)
    
    window.addEventListener 'mouseup', (e) =>
      if !e.target.closest('.fake_select')
        @hide_visibility(@fake_select)

  toggle_visibility: ->
    @fake_select.classList.toggle('open')
  
  hide_visibility: ->
    @fake_select.classList.remove('open')

window.grid ||= {}

window.grid.fake_select =
  setup: ->
    document.querySelectorAll('.fake_select').forEach (fake_select) ->
      fake_select = new FakeSelect(fake_select)
