import './vendor/gsap.min.js'
import './vendor/ScrollTrigger.min.js'
import './tictoc/environment.js.erb'
import './tictoc/preview.js'
import './tictoc/ui.js'
import './grid/menu.js.coffee'
import './grid/video.js.coffee'
import './grid/overview.js.coffee'
import './grid/location_map.js.coffee'
import './grid/full_width_image_block.js.coffee'
import './grid/filter.js.coffee'
import './grid/cookie_notice.js.coffee'
import './grid/home_link_block.js.coffee'
import './grid/accordion.js.coffee'
import './grid/home.js.coffee'
import './grid/fake_select.js.coffee'

var ready;

ready = function (callback) {
  if (document.readyState !== 'loading') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') {
        callback();
      }
    });
  }
};

ready(function () {
  tictoc.preview.setup();
  tictoc.ui.setup();
  grid.menu.setup();
  grid.video.setup();
  grid.overview.setup();
  grid.location_map.setup();
  grid.full_width_image_block.setup();
  grid.filter.setup();
  grid.cookie_notice.setup();
  grid.home_link_block.setup();
  grid.accordion.setup();
  grid.home.setup();
  grid.fake_select.setup();
});