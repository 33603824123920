window.grid ||= {}

grid.menu =
  setup: ->
    @add_listeners()

  add_listeners: ->
    return false unless document.querySelectorAll('.menu_toggle').length > 0

    document.querySelector('.menu_toggle').addEventListener 'click', (e) ->
      e.preventDefault()
      e.srcElement.classList.toggle('active')

      tl = gsap.timeline()

      if e.srcElement.classList.contains('active')
        tl.to(".menu_toggle rect:nth-child(1)", {duration: 0.2, y: 6, transformOrigin: 'center center'}, 0)
        tl.to(".menu_toggle rect:nth-child(3)", {duration: 0.2, y: -6, transformOrigin: 'center center'}, 0)

        tl.to(".menu_toggle rect:nth-child(3)", {duration: 0, opacity: 0, transformOrigin: 'center center'}, 0.2)

        tl.to(".menu_toggle rect:nth-child(1)", {duration: 0.2, rotate: 45, transformOrigin: 'center center'}, 0.2)
        tl.to(".menu_toggle rect:nth-child(2)", {duration: 0.2, rotate: -45, transformOrigin: 'center center'}, 0.2)
  
      else
        tl.to(".menu_toggle rect:nth-child(1)", {duration: 0.2, rotate: 0, transformOrigin: 'center center'}, 0)
        tl.to(".menu_toggle rect:nth-child(2)", {duration: 0.2, rotate: 0, transformOrigin: 'center center'}, 0)

        tl.to(".menu_toggle rect:nth-child(3)", {duration: 0, opacity: 1, transformOrigin: 'center center'}, 0.2)

        tl.to(".menu_toggle rect:nth-child(1)", {duration: 0.2, y: 0, transformOrigin: 'center center'}, 0.2)
        tl.to(".menu_toggle rect:nth-child(3)", {duration: 0.2, y: 0, transformOrigin: 'center center'}, 0.2)

      document.querySelector('body').classList.toggle('menu_open')
      e.srcElement.blur()

    document.querySelectorAll('.section_toggle').forEach (link) ->
      link.addEventListener 'click', (e) ->
        e.preventDefault()

        link.classList.toggle('active')

        if link.classList.contains('active')
          link.nextElementSibling.style.maxHeight = "#{link.nextElementSibling.scrollHeight}px"
        else
          link.nextElementSibling.style.maxHeight = 0

        link.closest('li').classList.toggle('open')
        link.blur()

    document.querySelectorAll('.dropdown.open').forEach (open_dropdown) ->
      setTimeout ->
        open_dropdown.style.maxHeight = "#{open_dropdown.scrollHeight}px"
      , 200