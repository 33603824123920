window.grid ||= {}

grid.video =
  setup: ->
    video_links = document.querySelectorAll('.video_source, .video_link')

    return unless video_links.length > 0

    if !document.cookie.match(/grid_cookies_consent=true/)
      video_links.forEach (link) ->
        grid.video.showCookieConsentMessage(link)
    else
      grid.video.loadAndSetupVideos(video_links)

  showCookieConsentMessage: (link) ->
    video_span = document.createElement('span')
    video_span.innerHTML = ' We use cookies to give you the best experience of our website. By <a href="/cookies-accepted" class="accept_cookies">accepting cookies</a>, you\'re agreeing to our use of cookies.'
    link.after(video_span)

  loadAndSetupVideos: (links) ->
    loadScript = (src) ->
      script = document.createElement('script')
      script.src = src
      document.querySelector('body').append(script)

    loadScript('//www.youtube.com/iframe_api')
    loadScript('//player.vimeo.com/api/player.js')

    setTimeout ->
      links.forEach (link) ->
        if link.nodeName.match(/VIDEO/)
          grid.video.setupCMSVideo(link)
        else
          grid.video.setupThirdPartyVideo(link)
    , 1000

  setupCMSVideo: (link) ->
    player = link
    player.controls = true
    poster = link.parentNode.querySelector('.video_block_poster')
    if poster
      poster.addEventListener 'click', (e) ->
        e.preventDefault()
        poster.style.display = 'none'
        player.play()

  setupThirdPartyVideo: (link) ->
    poster = link.parentNode.querySelector('.video_block_poster')
    if link.href.match(/youtube\.com|youtu\.be/)
      @setupYouTubeVideo(link, poster)
    else if link.href.match(/vimeo\.com/)
      @setupVimeoVideo(link, poster)

  setupYouTubeVideo: (link, poster) ->
    if poster
      poster.addEventListener 'click', (e) ->
        e.preventDefault()
        poster.style.display = 'none'
        player.playVideo()

    videoId = if link.href.match(/youtube\.com/)
      link.href.match(/v=([\w\-]+)/)[1]
    else if link.href.match(/youtu\.be/)
      link.href.match(/\.be\/([\w\-]*)/)[1]

    player = new YT.Player(link, videoId: videoId)

  setupVimeoVideo: (link, poster) ->
    if poster
      poster.addEventListener 'click', (e) ->
        e.preventDefault()
        poster.style.display = 'none'
        player.play()

    vimeo_video_container = document.createElement('div')
    link.parentNode.insertBefore(vimeo_video_container, link)

    link_ids = link.href.match(/vimeo\.com.*\/(\d+)(?:$|\/([\w]+))/)
    id = if link_ids[2]
      "https://player.vimeo.com/video/#{link_ids[1]}?h=#{link_ids[2]}"
    else
      link_ids[1]

    player = new Vimeo.Player(vimeo_video_container, id: id)
    link.remove()