window.grid ||= {}

grid.home =

  setup: ->
    @setup_home_video_banner()

  setup_home_video_banner: ->
    home_video_banner = document.querySelectorAll('.home .banner video')
    return unless home_video_banner.length

    banner_video_controls = document.querySelector('.banner_video_controls')

    if home_video_banner[0].paused
      home_video_banner[0].play()
      banner_video_controls.classList.add('playing')
    
    banner_video_controls.addEventListener 'click', (e) ->
      e.preventDefault()
      
      if home_video_banner[0].paused
        home_video_banner[0].play()
        banner_video_controls.classList.add('playing')
      
      else if !home_video_banner[0].paused
        home_video_banner[0].pause()
        banner_video_controls.classList.remove('playing')

