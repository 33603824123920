window.grid ||= {}

grid.full_width_image_block =
  setup: ->
    full_width_image_blocks = document.querySelectorAll(".full_width_image_block_content_block.parallax")
    return false unless full_width_image_blocks.length > 0

    full_width_image_blocks.forEach (block) ->
      gsap.to(block.querySelector('img'), {
        top: "-20%",
        ease: "none",
        scrollTrigger: {
          trigger: block,
          start: "top bottom",
          end: "bottom top",
          scrub: 0.8
        }
      })