window.grid ||= {}

grid.overview =
  setup: ->
    @setup_children()

  setup_children: ->
    children = document.querySelectorAll(".overview_child")
    return false unless children.length > 0

    children.forEach (child, idx) ->
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: child,
          start: "top 75%"
        }
      })

      tl.from(child, y: 100, opacity: 0, duration: 1)