window.grid ||= {}

grid.filter =

  setup: ->
    filters = document.querySelectorAll('.tag_filter select')
    return unless filters.length

    filters.forEach (filter) ->
      filter.addEventListener 'change', (e) ->
        e.target.closest('form').submit()