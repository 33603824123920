window.grid ||= {}

grid.home_link_block =

  setup: ->
    if window.matchMedia('(min-width: 850px)').matches
      home_link_block_content_block_links = document.querySelectorAll('.home_link_block_content_block_nav a')
      return unless home_link_block_content_block_links.length

      home_link_content_blocks = document.querySelectorAll('.home_link_content_block')

      home_link_block_content_block_links.forEach (home_link_block_content_block_link, idx) ->
        home_link_block_content_block_link.addEventListener 'mouseover', (e) ->
          home_link_content_blocks.forEach (home_link_content_block) ->
            home_link_content_block.classList.remove('active')
          
          home_link_content_blocks[idx].classList.add('active')